import styled from 'styled-components';
import { device } from 'styles/medias';

export const Container = styled.div`
  align-items: center;
  border-radius: 8px;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  flex-direction: column;
  justify-content: center;
  height: fit-content;
  box-shadow: none;
  box-shadow: none;
  padding: 1rem;

  @media ${device.sm} {
    box-shadow: 0 5px 0.5rem #e8e8e8;
    grid-template-columns: repeat(1, 1fr);
    padding: 6rem 3.5rem;
  }
`;

export const Content = styled.div`
  display: grid;
  justify-items: center;
  align-items: flex-start;
  grid-template-columns: repeat(1, 1fr);
  gap: 1rem;
  width: 100%;

  @media ${device.sm} {
    box-shadow: none;
    min-width: 32rem;
  }
`;
