import { useBorrower } from 'hooks/useBorrower';
import { ContentLoading } from 'pages/Dashboard/components/ContentLoading';
import { toFormatedPhone } from 'utils/formatters/to-formated-phone';

import { ProfileLineGroupDashboard } from '../ProfileLineGroupDashboard';

import { Container, Content } from './style';

export const ProfileDashboard = () => {
  const { borrowerData } = useBorrower();

  const data = [
    {
      title: 'Nome',
      value: borrowerData.data?.attributes?.name || '',
    },
    {
      title: 'E-mail',
      value: borrowerData.data?.attributes?.email || '',
    },
    {
      title: 'Telefone',
      value: toFormatedPhone(
        borrowerData.data?.attributes?.['mobile-number'] || '',
      ),
    },
  ];

  return (
    <Container>
      {!borrowerData ? (
        <ContentLoading />
      ) : (
        <Content>
          {data.map((item) => (
            <ProfileLineGroupDashboard
              key={item.title}
              title={item.title}
              value={item.value}
            />
          ))}
        </Content>
      )}
    </Container>
  );
};
